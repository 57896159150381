<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Experts list -->
    <div v-if="!isLoading" class="flex flex-wrap items-stretch mt-9" key="content">
      <ClinicalCaseCard
        v-for="clinicalcase in clinicalCases"
        :key="clinicalcase.id"
        :title="clinicalcase.title"
        :isAvailable="isUserHaveAccess"
        :link="casesUrl + clinicalcase.id"
      />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import ClinicalCaseCard from '@/components/clinicalcase/ClinicalCaseCard.vue'

export default {
  name: 'ClinicalCases',
  components: {
    ClinicalCaseCard,
    LoadingScreen,
    FadeTransition
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed:{
    ...mapState({
      clinicalCases: state => state.cases.cases,
      casesArea: state => state.cases.area
    }),
    ...mapGetters({
      isUserHaveAccess: 'isUserHaveAccess'
    }),
    casesUrl: function() {
      return '/' + this.$route.params.therapyarea_url + '/clinical-cases/';
    }
  },
  created: function() {
    if (this.clinicalCases && (this.casesArea == this.$route.params.therapyarea_url)) {
      this.isLoading = false
    } else {
      this.$store.dispatch('cases/getTherapyAreaCases', this.$route.params.therapyarea_url)
        .then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
