<template>
  <div class="self-auto pr-2 md:pr-4 lg:pr-8 pb-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3">

    <!-- Available card -->
    <router-link v-if="isUserActive && isAvailable" :to="link" class="flex cursor-pointer">
      <div class="bg-white h-full w-full rounded-lg shadow-md p-4 flex flex-wrap">
        <div class="self-start w-full">
          <p class="text-left">Клинический случай: </p>
          <h2 class="text-2xl pt-2 font-medium text-gray-700 max-w-lg leading-7 mx-auto text-left hover:text-primary-500">{{ title }}</h2>
        </div>
        <div class="self-end w-full">         
          <p v-if="author" class="text-md mx-auto text-secondary font-semibold">{{ author }}</p>
          <SolidButton :title="'Изучить'" :link="link" class="w-full"/>
        </div>
      </div>
    </router-link>

    <!-- Blocked card -->
    <div div v-if="!isUserActive || !isAvailable" class="bg-white h-full relative w-full rounded-lg shadow-md p-4 flex flex-wrap">
      <div class="self-start w-full">
        <div class="block w-full text-primary-500 pb-2 text-right text-sm font-semibold">
          Просмотр клинического случая доступнен только зарегистрированным пользователям
        </div>
        <p class="text-left">Клинический случай: </p>
        <h2 class="text-2xl pt-2 font-medium text-gray-700 max-w-lg leading-7 mx-auto text-left">{{ title }}</h2>
      </div>
      <div class="self-end w-full">
        <p v-if="author" class="text-md mx-auto text-secondary font-semibold">{{ author }}</p>
        <BlockedButton :title="'Изучить'" class="w-full"/>
      </div>
      <div class="text-gray-400 text-6xl flex justify-center items-center absolute top-0 left-0 w-full h-full">
            <font-awesome-icon icon="lock" class="align-middle" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import SolidButton from '@/components/_partials/SolidButton.vue'
import BlockedButton from '@/components/_partials/BlockedButton.vue'

export default {
  name: 'ClinicalCaseCard',
  components: {
    SolidButton,
    BlockedButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isAvailable: {
      type: Boolean,
      required: true
    },
    link: String,
    description: String,
    author: String
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    })
  }
}
</script>
